<template>
    <div>
        <div class="box">
            <el-page-header @back="$router.go(-1)" :content="$t('tQADetail')"></el-page-header>
        </div>
        <el-card class="mt20">
            <el-form label-position="left" :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm row">
                <el-form-item class="col-6 mb0" :label="$t('QA.username')" prop="username">
                    <div>{{ruleForm.user.username}}</div>
                </el-form-item>
                <el-form-item class="col-6 mb0" :label="$t('QA.real_name')" prop="real_name">
                    <div>{{ruleForm.user.real_name}}</div>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="mt20">
            <el-form label-position="left" :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm row">
                <el-form-item class="col-6" :label="$t('QA.create_time')" prop="create_time">
                    <div>{{ruleForm.create_time}}</div>
                </el-form-item>
                <el-form-item class="col-6" :label="$t('QA.update_time')" prop="update_time">
                    <div>{{ruleForm.update_time}}</div>
                </el-form-item>
                <el-form-item class="col-6" :label="$t('QA.title')" prop="answer">
                    <div>{{ruleForm.title}}</div>
                </el-form-item>
                <el-form-item class="col-12" :label="$t('QA.question')" prop="answer">
                    <div class="word_break" v-html="ruleForm.question"></div>
                </el-form-item>
                <el-form-item class="col-12" :label="$t('QA.answer')" prop="answer">
                    <div v-html="ruleForm.answer"></div>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import { getQADetail } from '@/api/index'
export default {
    name:'editQA',
    data(){
        return {
            ruleForm:{
               answer:'',
               user:{
                   username:'',
                   real_name:''
               }
            }
        }
    },
    computed:{
        id(){
			return Number.isNaN(Number(this.$route.query.id)) ? undefined : Number(this.$route.query.id)
        }
    },
    methods:{
        async get_info(){
            const information = await getQADetail(this.id)
            this.ruleForm = {...information}
        },
    },
    async created(){
        if(this.id === undefined) return
		this.get_info()
    },

}
</script>
<style land="scss" scoped>
    .el-date-editor{
        margin-right:10px;
    }
    .bbb{
	position: relative;
	padding-bottom: 50px;
    }
    .bbb .btn{
        position: absolute;
        bottom:10px;
        right: 20px;
    }
</style>
